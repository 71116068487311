body {
  margin: 0;
  font-family: 'Roboto','bdfont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
@font-face {
  font-family: 'bdfont';
  src: local('bdfont'), url(./fonts/bdfont.ttf) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Rajdhani-Medium';
  src: local('Rajdhani-Medium'), url(./fonts/bdfont.ttf) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
p{
  margin:0 !important;
}
.btn-close {
	position: absolute;
	z-index: 1;
	float: right;
	right: 7px;
	top: 7px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,'bdfont', 'Courier New',
    monospace;
}
.font-small{
  font-size:13px !important;

}
.w-20{
  width:16%;
  padding:0;
}
.onlinetext{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-size-small{
  font-size: 14px;
}
.twolinetext{
  
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-active{
  background-color: #c0c1c2;
}
.secondslideritemwidth{
  width:100px;
  height: 100px;
}
.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: none;
	outline: 0;
	box-shadow: none;
}
.form-select:focus {
	border-color:none !important;
	outline: 0;
	box-shadow: none;
}
.bg-lightgray{
  background-color:#efefef;
}
.discountbox{
  overflow: hidden;
  position: relative;
}
.absolutebox {
	position: absolute;
	width: 150px;
	height: 150px;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0.2;
	margin-left: 220px;
	margin-top: -66px;
  transition: 0.5s;
}
.absolutebox2 {
	position: absolute;
	width: 150px;
	height: 150px;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0.2;
	margin-left: 50px;
	margin-top: -66px;
}
.absolutebox3 {
	position: absolute;
	width: 150px;
	height: 150px;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0.2;
	margin-left: 74%;
	margin-top: -66px;
}
.absolutebox4 {
	position: absolute;
	width: 150px;
	height: 150px;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0.2;
	margin-left: 420px;
	margin-top: 100px;
}
.radiousRound{
  border-radius: 50% !important;
}
.homeRoundIcons{
  width:60px;
  height:60px;
  margin: auto;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: flex;
}
.homeRoundIconsInner{
  width:50px;
  height:50px;
  margin: auto;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.menu_top_text p{
  line-height: 22px;
}
@keyframes customticker {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}


/* Formatting the full-width ticker wrapper background and font color */
.tickerwrap {
  width: 100%;
  overflow: hidden;
  background-color: #f4511e;
  color: #fff;
}


/* Formatting the ticker content background, font color, padding and exit state */
.ticker {
  display: inline-block;
  white-space: nowrap;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: customticker;
  animation-name: customticker;
  -webkit-animation-duration: 45s;
  animation-duration: 45s;
}
.selfcolor{
  color:#f4511e !important;
}
.accent_color{
  color: #004CCF !important;
}
.selfbggradient{
  background: linear-gradient(#F74200,#F5BE00);
}
.categorybuttons{
  border: 0.1px solid #efefef;
  transition: ease-in-out 0.3s;
}

.ordersuccesscheck{
  font-size: 100px;
}
.btn_gradient{
  background: linear-gradient(blue,purple)
}
.selfbgcolor{
  background-color: #f4511e !important;
}
.login_icons {
	position: absolute;
	margin-left: 5px;
}
.notice_front {
	position: absolute;
	background: #fff;
	width: 40px;
	align-content: center;
	text-align: center;
	z-index: 1;
	height: 36px;
}
.relativeBox{
  position: relative;
  overflow: hidden;
}
.tickerwrap{
  margin-top:10px;
}
.homeicontext{
  color:#222;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-scrollbar{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.marginLeftArrow{
  margin-left: -15px;
}
.marginRightArrow{
  margin-right: -15px;
}
.single_post{
  width: 180px;
  float: left;
  position: relative;
}
.demand_absolute{
  width:30px;
  height:30px;
  position:absolute;
}
.pin_slider{
  overflow: scroll;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.footer_link {
	font-size: 28px !important;
	text-decoration: none;
	width: 45px;
	height: 45px;
	border-radius: 25%;
	display: block;
	float: left;
	text-align: center;
	font-family: arial !important;
	border: 2px solid white;
}
.footer_boxs {
	width: 25%;
	text-align: center;
	border-radius: 10px;
}
.footer_images {
	width: auto;
	height: 45px;
	margin: 0;
}
.shop_product_name {
	font-size: 25px;
	color: #F4511E;
	font-weight: 600;
	line-height: 30px;
}
.shop_pd_mainimage_container{
  width: 250px;
  height: auto;
}
.shop_pd_mainimage{
  object-fit: cover; 
  width:250px;
}
.shop_pd_flex_content{
  flex:33% !important;
}
.rec_iconround {
	width: 90px;
	padding: 15px;
	border: 1px solid red;
	border-radius: 50% !important;
	height: 90px;
}
.rec_operator_icon{
  width:30px;
  height:30px;
}
.rec_scroll_operator{
  overflow: scroll;
  height: 350px;
}
.nav-tabs .nav-link {
	color: #222;
}
.nav-tabs .nav-link .active {
	color: #f4511e !important;
}
.hidescroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.rec_absolute_tap_btn {
	background: orange;
	position: absolute;
	bottom: -238px;
	border-radius: 162px;
	width: 300px;
	height: 300px;
	left: 20%;
	padding-top: 10px;
  z-index: 1;
}

.rec_rotator_box {
	width: 310px;
	height: 310px;
	transform: rotate(-180deg);
	position: absolute;
	bottom: -240px;
	left: 19%;
  transition:3s;
}
.rec_absolute_tap_btn2 {
	width: 310px;
	height: 155px;
	background-color: gold;
	border-top-left-radius: 150px;
	border-top-right-radius: 150px;
	border: 10px solid gray;
	border-bottom: 0;
	box-sizing: border-box;
	
	position: absolute;
	

}
.rec_absolute_tap_btn3 {
	width: 290px;
	height: 146px;
	background-color: gold;
	border-top-left-radius: 150px;
	border-top-right-radius: 150px;
	margin-left: 10px;
	border-bottom: 0;
	box-sizing: border-box;
	position: absolute;
	border: 1px solid gray;
	margin-top: 155px;
	transform: rotate(180deg);
}
.offer_details_text{
  font-size: 18px !important;
}
.ondemand_single_suggestion{
  width:200px;
}
.shop_gradient_top{
  background: linear-gradient(90deg,#F74200,#F5BE00);
}

.shop_dash_income {
	background: linear-gradient(90deg,#F5BE00,#F74200);
	float: right;
	padding: 10px;
	border-radius: 10px;
	padding-left: 20px;
	padding-right: 80px;
	margin-right: -40px;
	font-size: 25px;
	color: #fff;
}
a{
  text-decoration: none;
}
.flash_item {
    
  width: 171px !important;
}
.categoryImage{
  width: 80px;
  height: 80px;
}
.category_boxex{
  max-width: 100px;
}
.header_links{
  transition: 0.5s;
}
.header_links:hover{
 color: #F4511E;
}
.sige_menu_item{
  transition: 0.5s;
  font-size: 17px;
  white-space: nowrap;
  font-weight: 300;
}
.discountbox:hover > .absolutebox{
  width: 200px;
  height: 200px;
  margin-left: 165px;
  margin-top: -90px;
}
.sige_menu_item:hover{
  color: #F4511E;

}
.category_absolute{
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0.2;
}
.category_box{
  border-right:3px solid #F4511E;
}
div.a {
width: 50px;
height:50px;
 background-color:red;
position:fixed;
    
}
.myorderimage{
  width:100px;
  height:100px;
}
.myorder_text{
  font-size: 18px;
  font-weight: 600;
}
.offcanvas.offcanvas-start {

	left: -90px !important;

	padding-left: 90px !important;
}
.login_input_box{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.justforyouitem{
  height:200px;
}
.imgprofilebox {
	width: 100px;
	height: 100px;
	margin: auto;
	border-radius: 50% !important;
	overflow: hidden;
	border: 2px solid white;


}
.profile_pic_box {
	width: 25px;
	height: 25px;
	float: left;
	margin-right: 10px;
}
.profile_mini_image{
  width:25px;
  height:25px;
  border-radius:50%;
}
.scrollerleft{
  width:25px;
  height:25px;
  background-color: #ff9a67;
}
.scrollerright{
  width:25px;
  height:25px;
  background-color: #ff9a67;
}
.scroller{
  width:80px;
}
.favorite_remove {
	position: absolute;
	float: right;
	right: 0px;
	background: #222;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	border-radius: 5px;
}
.chat_button {
	position: fixed;
	width: 120px;
  text-align: center;
	height: 50px;
	right: 0;
	z-index: 1;
	bottom: 110px;
}
.totorial_button {
	position: fixed;
	right: -20px;
	bottom: 200px;
  z-index: 1;

}
.fontsize {
	font-size: 40px;
}
.order_button {
	fill: #FFFFFF;
	color: #FFFFFF;
	background-color: #129202;
	border-style: solid;
	border-width: 2px 2px 2px 2px;
	border-color: #CE6D33;
	border-radius: 50px 50px 50px 50px;
	text-decoration: none;
	width: 60%;
	text-align: center;
}
.durationtext{
  font-size: 30px !important;
}
.addressbox{
  width:50% !important;
}
.pricefont{
  line-height: 30px;
}
@media only screen and (max-width: 768px) {
  body{
    font-size: 13px !important;
  }
  .pricefont{
    line-height: 10px;
  }
  .addressbox{
    width:100% !important;
  }
  .order_button{
    width: 100%;
    font-size: 21px;
  }
  .fontsize{
    font-size: 19px;
  }
  .rec_absolute_tap_btn {
    background: orange;
    position: absolute;
    bottom: -238px;
    border-radius: 162px;
    width: 300px;
    height: 300px;
    left: 6%;
    padding-top: 10px;
    z-index: 1;
  }
  
  .rec_rotator_box {
    width: 310px;
    height: 310px;
    transform: rotate(-180deg);
    position: absolute;
    bottom: -240px;
    left: 5%;
    transition:3s;
  }
  .totorial_button {
    position: fixed;
    right: -20px;
    top: 20%;
    bottom: auto;
  }
  .mobile_container{
    padding:0 !important;
    padding-top: 15px !important;
  }
  .justforyouitem{
    height:150px;
  }
  .mobileFooter{
    position:fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    left:0;
  }
  .mobileheader{
    position: fixed;
    background-color: #fff;
    z-index: 3;
    top: 0;
    left: 0;

  }
  .login_popup{

      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      display: inline;
    
      
  }
  .login_body {
    padding-top: 45% !important;
  }
  .myorderimage{
    width:40px;
    height:40px;
  }
  .myorder_text{
    font-size: 11px;
    font-weight: 600;
  }
  .myordertab {
    font-size: 13px !important;
  }
  .shop_dash_name{
    font-size: 15px !important;
  }
  .shop_dash_text{
    font-size: 15px !important;
  }
  .subcategory_box {
    width: 45% !important;
    margin: 5px !important;
  }
  .homeRoundIcons {
    width: 45px;
    height: 45px;
    
  }
  .form-label{
    display: none !important;
  }
  .shop_ck_count_btns {
    padding: 8px !important;
  }
  .checkout_container{
    padding: 0 !important;
  }
  .shop_product_name {
    font-size: 17px;
    color: #F4511E;
    font-weight: 600;
    line-height: 24px;
  }
  .shop_pd_btns {
    font-size: 11px;
  }
  .self_pd_stock {
    
    
  }
  .offer_details_text{
    font-size: 14px !important;
  }
  .nav-link{
    font-size: 16px !important;
  }
  .select_operator{
    font-size: medium;
  }
  .rec_iconround {
    width: 50px;
    padding: 8px;
    border: 1px solid red;
    border-radius: 50% !important;
    height: 50px;

  }
  .rounded_90ged{
    transform:rotate(0deg) !important;
  }
  .shop_section_title{
    font-size: 12px !important;
  }
  .shop_section_top_text{
    font-size: 15px !important;
  }
  .shop_section_button {
    font-size: 12px !important;
    margin-bottom: 5px !important;
  }
  .shop_section_banner{
    width: 100% !important;
  }
  .popular_cat{
    padding: 0 !important;
  }
  .shop_section_bottom_text{
    font-size: 12px !important;

  }
  .popular_cat div img {
    height: 50px !important;
    width: 50px !important;
  }
  .homeRoundIconsInner {
    width: 35px;
    height: 35px;
    
  }
  .homeicons{
    font-size: 18px !important;
  }
  .iconsSlidercarousel{
    width: 100% !important;
  }
  .iconsSliderContainer{
    margin:0 !important;
  }
  .container-fluid{
    padding:5px !important;
  }
  .top_bar{
    padding:0 !important;
    margin-top: 10px;
  }
  .sliders_part {
    height: 0 !important;
    padding: 0 !important;
  }
  .shopRadiusIcons {
    margin-bottom: 0px !important;
  }
  .shop_icons_box  {
    padding: 0 !important;
    
  }
  .shop_icons_box p {
    
    line-height: 16px;
  }
  .discount_boxex {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .flash_item {
    
    width: 171px !important;
  }
  .tickerwrap{
    margin-top:0px;
  }
}