body {
    padding: 0;
    margin: 0;
    background: #f3f3f3;
  }
  h1,h2,h3 {
      margin: 0;
  }
  p {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
  }
  a {
      color: #005eeb;
  }
  a:hover {
    color: #ffb200;
  }
  .teamWrapper {
      margin-top: 50px;
  }
  .teamGrid {
      display: grid;
      grid-template-columns: 32.33% 32.33% 32.33%;
      column-gap: 1.5%;
      margin-top: 100px;
  }
  .avatar {
      position: absolute;
      left: 0;
      right: 0;
      top: -80px;
      text-align: center;
  }
  .teamcolinner {
      position: relative;
  }
  .avatar > img {
      width: 150px;
      margin: auto;
      border-radius: 50%;
      height: 170px;
      border: 1px solid rgb(170 170 173/ 1);
      box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
  }
  .teamcolinner {
      position: relative;
      border: 1px dashed #ddd;
      min-height: 100px;
      background: #fff;
      z-index: 9;
  }
  .teamcol {
      padding: 15px;
      background: #fff;
      border-radius: 10px;
      position: relative;
      transition: transform 1s ease-in-out;
  }
  .teamcol:hover {
      transform: translateY(-30px);
      box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
      transition: transform 1s ease-in-out;
  }
  .teamcol:before {
      content: "";
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      background: -webkit-linear-gradient(#f4511e, #fa7a4c);
      border-top-right-radius: 10px;
      transition: width 1s ease-in-out;
  }
  .teamcol:after {
      content: "";
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: -webkit-linear-gradient(#0052cc, #005fec);
      border-bottom-left-radius: 10px;
      transition: width 1s ease-in-out;
  }
  .teamcol:hover::before, .teamcol:hover::after {
      width: 100%;
      transition: width 1s ease-in-out;
  }
  .member-name {
      margin-top: 100px;
  }
  .member-info {
      padding: 10px 20px;
  }
  .social-listing {
      align-items: center;
      justify-content: center;
      display: flex;
      list-style: none;
      padding: 0;
  }
  .social-listing >li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #f4f5f7;
      border-radius: 50%;
      margin: 5px;
  }